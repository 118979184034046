// import Service from "@/api/userservice";
import helper from "@/api/helper";

export default {
    defineMenu() {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let role = userLogin.role;
        // console.log('defineMenu - userlogin', userLogin, role)

        // // if(userLogin && userLogin.username != 'root')  {

        // // 	const {data} = await Service.getDetailUser("/users/"+userLogin.username)

        // // 		let roles = data.user_roles
        if (role != "admin") {
            let roles = helper.getMenuRoles(userLogin.listperm);
            let menuRoles = this.getMenu(roles);
            // console.log("menuRoles", menuRoles, roles);
            // console.log('Member')

            return menuRoles;

            // return [
            // 	{
            // 		header: "MODULE",
            // 	},
            // 	{c
            // 		title: "Input Data",
            // 		icon: "FileTextIcon",
            // 		children: [
            // 			{
            // 				title: "Meteorologi",
            // 				route: "meteorologi",
            // 			},
            // 			{
            // 				title: "Klimatologi",
            // 				route: "klimatologi",
            // 			},
            // 			{
            // 				title: "Ingest",
            // 				route: "geofisika",
            // 			},
            // 		],
            // 	},
            // 	{
            // 		title: "Monitoring",
            // 		icon: "MonitorIcon",
            // 		// route: 'monitoring',
            // 		children: [
            // 			{
            // 				title: "Meteorologi",
            // 				route: "monitoring-meteorologi",
            // 			},
            // 			{
            // 				title: "Klimatologi",
            // 				// route: { name: 'apps-invoice-preview', params: { id: 4987 } },
            // 				route: "monitoring-klimatologi",
            // 			},
            // 			{
            // 				title: "Geofisika",
            // 				route: "monitoring-geofisika",
            // 			},
            // 		],
            // 	},
            // 	{
            // 		title: "Metadata Stasiun",
            // 		icon: "PackageIcon",
            // 		//route: 'metadata',
            // 		children: [
            // 			{
            // 				title: "Metadata",
            // 				route: "metadata",
            // 			}
            // 		],
            // 	},
            // ]
        }

        // console.log('admin')
        return [
            {
                header: "MODULE",
            },
            {
                title: "Input Data",
                icon: "FileTextIcon",
                children: [
                    {
                        title: "Meteorologi",
                        route: "meteorologi",
                    },
                    {
                        title: "Klimatologi",
                        route: "klimatologi",
                    },
                    {
                        title: "GTS Messages",
                        route: "gts_messages",
                    },
                ],
            },
            {
                title: "Output Data",
                icon: "FileMinusIcon",
                children: [
                    {
                        title: "Derive Data",
                        route: "derive",
                    },
                    {
                        title: "Export Data",
                        route: "export",
                    },
                    {
                        title: "Report",
                        route: "reports",
                    },
                    {
                        title: "Integrasi",
                        route: "integrasi",
                    },
                    {
                        title: "Extract Gts",
                        route: "extract-gts",
                    },
                ],
            },
            {
                title: "Ingest",
                icon: "DatabaseIcon",
                children: [
                    {
                        title: "Data Historis",
                        route: "data-historis",
                    },
                    {
                        title: "Data Lab KU",
                        route: "data-lab-ku",
                    },
                    // {
                    // 	title: "Data Raw",
                    // 	route: "data-raw",
                    // },
                    // {
                    // 	title: "Monitoring",
                    // 	route: "monitoring-ingest",
                    // },
                ],
            },
            {
                title: "Monitoring",
                icon: "MonitorIcon",
                children: [
                    {
                        title: "Meteorologi",
                        route: "monitoring-meteorologi",
                    },
                    {
                        title: "Klimatologi",
                        route: "monitoring-klimatologi",
                    },
                    {
                        title: "Data Integrasi",
                        route: "monitoring-data-integrasi",
                    },
                    {
                        title: "GTS",
                        route: "monitoring-gts",
                    },
                    // {
                    // 	title: "Geofisika",
                    // 	route: "monitoring-geofisika",
                    // },
                ],
            },
            {
                title: "Visualization",
                icon: "AirplayIcon",
                children: [
                    {
                        title: "Data Raw",
                        route: "raw_visualisasi",
                    },
                    {
                        title: "Data Parameter",
                        route: "visualisasi",
                    },
                    // {
                    // 	title: "Ketersediaan Data",
                    // 	route: "availability",
                    // },
                    {
                        title: "Quality Assesment",
                        route: "visualisasi_qa",
                    },
                ],
            },
            {
                title: "Quality Control",
                icon: "SearchIcon",
                children: [
                    {
                        title: "Configuration",
                        route: "qc-configuration",
                    },
                    {
                        title: "Data Suspect",
                        route: "qc-data-suspect",
                    },
                    {
                        title: "Ruleset",
                        route: "qc-ruleset",
                    },
                    {
                        title: "Monitoring",
                        route: "qc-monitoring",
                    },
                ],
            },
            {
                title: "Metadata Station",
                icon: "PackageIcon",
                children: [
                    {
                        title: "Metadata",
                        route: "metadata",
                    },
                    {
                        title: "Nimos Sync",
                        route: "synchronization",
                    },
                ],
            },
            {
                title: "User Management",
                icon: "UsersIcon",
                children: [
                    {
                        title: "Manage Users",
                        route: "manage-users",
                    },
                    {
                        title: "Manage Roles",
                        route: "manage-roles",
                    },
                    // {
                    // 	title: "Manage Assign",
                    // 	route: "manage-assign",
                    // },
                ],
            },
            // {
            // 	title: "Log",
            // 	icon: "HexagonIcon",
            // 		children: [
            // 		{
            // 			title: "Aktifitas Pengguna",
            // 			route: "aktifitas-pengguna",
            // 		}
            // 	],
            // },
            {
                title: "Setting",
                icon: "SettingsIcon",
                children: [
                    {
                        title: "Manage Balai",
                        route: "region",
                    },
                    {
                        title: "Manage Provinsi",
                        route: "provinsi",
                    },
                    {
                        title: "Manage Kabupaten",
                        route: "kabupaten",
                    },
                    // {
                    // 	title: "Manage Kelurahan",
                    // 	route: "kelurahan",
                    // },
                    {
                        title: "Manage Element",
                        route: "manage-element",
                    },
                    {
                        title: "Manage Properties",
                        route: "manage-properties",
                        children: [
                            {
                                title: "Tooltips",
                                route: "manage-tooltips",
                            },
                            {
                                title: "Selectbox",
                                route: "manage-select",
                            },
                        ],
                    },

                    {
                        title: "Manage Usage",
                        route: "manage-usage",
                    },
                    {
                        title: "Manage Pejabat",
                        route: "pejabat",
                    },
                    // {
                    // 	title: "Manage Observer",
                    // 	route: "observer",
                    // },
                ],
            },
        ];
    },

    getMenu(roles) {
        let menus = [];
        if (roles) {
            menus.push({
                header: "MODULE",
            });

            if (roles.hasOwnProperty("MegaEntry")) {
                menus.push({
                    title: "Input Data",
                    icon: "FileTextIcon",
                    children: [
                        {
                            title: "Meteorologi",
                            route: "meteorologi",
                        },
                        {
                            title: "Klimatologi",
                            route: "klimatologi",
                        },
                        {
                            title: "GTS Messages",
                            route: "gts_messages",
                        },
                    ],
                });
            }

            if (roles.hasOwnProperty("Layanan")) {
                let outputMenu = {
                    title: "Output Data",
                    icon: "FileMinusIcon",
                    children: [],
                };

                if (roles.Layanan.indexOf("Export") !== -1) {
                    outputMenu.children.push({
                        title: "Derive Data",
                        route: "derive",
                    });
                }

                if (roles.Layanan.indexOf("Export") !== -1) {
                    outputMenu.children.push({
                        title: "Export Data",
                        route: "export",
                    });
                }

                if (roles.Layanan.indexOf("Report") !== -1) {
                    outputMenu.children.push({
                        title: "Report",
                        route: "reports",
                    });
                }

                if (roles.Layanan.indexOf("Export") !== -1) {
                    outputMenu.children.push({
                        title: "Integrasi",
                        route: "integrasi",
                    });
                }

                if (roles.Layanan.indexOf("Export") !== -1) {
                    outputMenu.children.push({
                        title: "Extract Gts",
                        route: "extract-gts",
                    });
                }

                menus.push(outputMenu);
            }

            if (roles.hasOwnProperty("Ingest")) {
                menus.push({
                    title: "Ingest",
                    icon: "DatabaseIcon",
                    children: [
                        {
                            title: "Data Historis",
                            route: "data-historis",
                        },
                        {
                            title: "Data LAB KU",
                            route: "data-lab-ku",
                        },
                        // {
                        // 	title: "Data Raw",
                        // 	route: "data-raw",
                        // },
                        // {
                        // 	title: "Monitoring",
                        // 	route: "monitoring-ingest",
                        // },
                    ],
                });
            }

            if (roles.hasOwnProperty("Monitoring")) {
                menus.push({
                    title: "Monitoring",
                    icon: "MonitorIcon",
                    children: [
                        {
                            title: "Meteorologi",
                            route: "monitoring-meteorologi",
                        },
                        {
                            title: "Klimatologi",
                            route: "monitoring-klimatologi",
                        },
                        {
                            title: "Data Integrasi",
                            route: "monitoring-data-integrasi",
                        },
                        {
                            title: "GTS",
                            route: "monitoring-gts",
                        },
                        // {
                        // 	title: "Geofisika",
                        // 	route: "monitoring-geofisika",
                        // },
                    ],
                });
            }

            if (roles.hasOwnProperty("Visualization")) {
                menus.push({
                    title: "Visualisasi",
                    icon: "AirplayIcon",
                    children: [
                        {
                            title: "Data Raw",
                            route: "raw_visualisasi",
                        },
                        {
                            title: "Data Parameter",
                            route: "visualisasi",
                        },
                        {
                            title: "Quality Assesment",
                            route: "visualisasi_qa",
                        },
                    ],
                });
            }

            if (roles.hasOwnProperty("QC")) {
                let outputMenu = {
                    title: "Quality Control",
                    icon: "SearchIcon",
                    children: [],
                };

                if (roles.QC.indexOf("Configuration") !== -1) {
                    outputMenu.children.push({
                        title: "Configuration",
                        route: "qc-configuration",
                    });
                }

                if (roles.QC.indexOf("Monitor") !== -1) {
                    outputMenu.children.push({
                        title: "Data Suspect",
                        route: "qc-data-suspect",
                    });
                }

                if (roles.QC.indexOf("Ruleset") !== -1) {
                    outputMenu.children.push({
                        title: "Ruleset",
                        route: "qc-ruleset",
                    });

                    outputMenu.children.push({
                        title: "Monitoring",
                        route: "qc-monitoring",
                    });
                }

                menus.push(outputMenu);
            }

            if (roles.hasOwnProperty("Station")) {
                menus.push({
                    title: "Metadata Stasiun",
                    icon: "PackageIcon",
                    //route: 'metadata',
                    children: [
                        {
                            title: "Metadata",
                            route: "metadata",
                        },
                        {
                            title: "Nimos Sync",
                            route: "synchronization",
                        },
                    ],
                });
            }

            if (roles.hasOwnProperty("User")) {
                let outputMenu = {
                    title: "User Management",
                    icon: "UsersIcon",
                    children: [],
                };

                if (roles.User.indexOf("Manage") !== -1) {
                    outputMenu.children.push({
                        title: "Manage Users",
                        route: "manage-users",
                    });
                }

                if (roles.hasOwnProperty("Role") !== -1) {
                    outputMenu.children.push({
                        title: "Manage Roles",
                        route: "manage-roles",
                    });
                }

                menus.push(outputMenu);
            }

            if (roles.hasOwnProperty("Setting")) {
                menus.push({
                    title: "Setting",
                    icon: "SettingsIcon",
                    children: [
                        {
                            title: "Manage Balai",
                            route: "region",
                        },
                        {
                            title: "Manage Provinsi",
                            route: "provinsi",
                        },
                        {
                            title: "Manage Kabupaten",
                            route: "kabupaten",
                        },
                        {
                            title: "Manage Element",
                            route: "manage-element",
                        },
                        {
                            title: "Manage Properties",
                            route: "manage-properties",
                            children: [
                                {
                                    title: "Tooltips",
                                    route: "manage-tooltips",
                                },
                                {
                                    title: "Selectbox",
                                    route: "manage-select",
                                },
                            ],
                        },
                        {
                            title: "Manage Usage",
                            route: "manage-usage",
                        },
                        {
                            title: "Manage Pejabat",
                            route: "pejabat",
                        },
                    ],
                });
            }
        }

        return menus;
    },
};

// export default [
// 	{
// 		header: "MODULE",
// 	},
// 	{
// 		title: "Input Data",
// 		icon: "FileTextIcon",
// 		children: [
// 			{
// 				title: "Meteorologi",
// 				route: "meteorologi",
// 			},
// 			{
// 				title: "Klimatologi",
// 				// route: { name: 'apps-invoice-preview', params: { id: 4987 } },
// 				route: "klimatologi",
// 			},
// 		// {
// 		//   title: "Ingest",
// 		//   route: "geofisika",
// 		// },
// 		],
// 	},
// 	{
// 		title: "Output Data",
// 		icon: "FileMinusIcon",
// 		children: [
// 			{
// 				title: "Export Data",
// 				route: "export",
// 			},
// 			{
// 				title: "Layanan",
// 				route: "pelayanan",
// 			},
// 			{
// 				title: "Laporan",
// 				route: "reports",
// 			},
// 		],
// 	},
// 	//menu ingest
// 	{
// 		title: "Ingest",
// 		icon: "DatabaseIcon",
// 		//route: 'admin',
// 		children: [
// 			{
// 				title: "Data Historis",
// 				route: "data-historis",
// 			},
// 			{
// 				title: "Data Raw",
// 				route: "data-raw",
// 			},
// 			{
// 				title: "Monitoring",
// 				route: "monitoring-ingest",
// 			},
// 		],
// 	},
// 	{
// 		title: "Monitoring",
// 		icon: "MonitorIcon",
// 		// route: 'monitoring',
// 		children: [
// 			{
// 				title: "Meteorologi",
// 				route: "monitoring-meteorologi",
// 			},
// 			{
// 				title: "Klimatologi",
// 				// route: { name: 'apps-invoice-preview', params: { id: 4987 } },
// 				route: "monitoring-klimatologi",
// 			},
// 			{
// 				title: "Geofisika",
// 				route: "monitoring-geofisika",
// 			},
// 		],
// 	},
// 	{
// 		title: "Visualisasi",
// 		icon: "AirplayIcon",
// 		// route: visualisasy
// 		children: [
// 			{
// 				title: "Visualisasi Data Raw",
// 				route: "raw_visualisasi",
// 			},
// 			{
// 				title: "Visualisasi Data Parameter",
// 				route: "visualisasi",
// 			},
// 			{
// 				title: "Ketersediaan Data",
// 				route: "availability",
// 			},
// 		],
// 	},
// 	{
// 		title: "Quality Control",
// 		icon: "SearchIcon",
// 		//route: 'quality-control',
// 		children: [
// 		{
// 			title: "Rule Sets",
// 			route: "rule-sets",
// 		},
// 		{
// 			title: "QC Monitor",
// 			route: "qc-monitor",
// 		},
// 		],
// 	},
// 	{
// 		title: "Metadata Stasiun",
// 		icon: "PackageIcon",
// 		//route: 'metadata',
// 		children: [
// 		{
// 			title: "Metadata",
// 			route: "metadata",
// 		},
// 		{
// 			title: "Synchronization",
// 			route: "synchronization",
// 		},
// 		// {
// 		//   title: "Laporan Metadata",
// 		//   route: "laporan-metadata",
// 		// },
// 		],
// 	},
// 	{
// 		title: "User Management",
// 		icon: "UsersIcon",
// 		//route: 'admin',
// 		children: [
// 		{
// 			title: "Manage User",
// 			route: "manage-users",
// 		},
// 		{
// 			title: "Manage Roles",
// 			route: "manage-roles",
// 		},
// 		{
// 			title: "Manage Assign",
// 			route: "manage-assign",
// 		},
// 		],
// 	},
// 	{
// 		title: "Log",
// 		icon: "HexagonIcon",
// 		//route: 'admin',
// 		children: [
// 		{
// 			title: "Aktifitas Pengguna",
// 			route: "aktifitas-pengguna",
// 		},
// 		// {
// 		//   title: "Proses Ingest",
// 		//   route: "proses-ingest",
// 		// },
// 		],
// 	},
// 	{
// 		title: "Setting",
// 		icon: "SettingsIcon",
// 		//route: 'admin',
// 		children: [
// 			{
// 				title: "Manage Balai",
// 				route: "region",
// 			},
// 			{
// 				title: "Manage Provinsi",
// 				route: "provinsi",
// 			},
// 			{
// 				title: "Manage Kabupaten",
// 				route: "kabupaten",
// 			},
// 			{
// 				title: "Manage Kelurahan",
// 				route: "kelurahan",
// 			},
// 			{
// 				title: "Manage Parameter",
// 				route: "manage-parameter",
// 				children: [
// 				{
// 					title: "Tooltips",
// 					route: "manage-tooltips",
// 				},
// 				{
// 					title: "Selectbox",
// 					route: "manage-select",
// 				},
// 				],
// 			},

// 			{
// 				title: "Manage Group Usage",
// 				route: "manage-group",
// 			},
// 			{
// 				title: "Manage Pejabat",
// 				route: "pejabat",
// 			},
// 			{
// 				title: "Manage Observer",
// 				route: "observer",
// 			},
// 		],
// 	},

//   //// ORIGINAL VUEXY
//   // {
//   //   header: 'Apps & Pages',
//   // },
//   // {
//   //   title: 'Email',
//   //   route: 'apps-email',
//   //   icon: 'MailIcon',
//   // },
//   // {
//   //   title: 'Chat',
//   //   route: 'apps-chat',
//   //   icon: 'MessageSquareIcon',
//   // },
//   // {
//   //   title: 'Todo',
//   //   route: 'apps-todo',
//   //   icon: 'CheckSquareIcon',
//   // },
//   // {
//   //   title: 'Calendar',
//   //   route: 'apps-calendar',
//   //   icon: 'CalendarIcon',
//   // },
//   // {
//   //   title: 'Invoice',
//   //   icon: 'FileTextIcon',
//   //   children: [
//   //     {
//   //       title: 'List',
//   //       route: 'apps-invoice-list',
//   //     },
//   //     {
//   //       title: 'Preview',
//   //       route: { name: 'apps-invoice-preview', params: { id: 4987 } },
//   //     },
//   //     {
//   //       title: 'Edit',
//   //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
//   //     },
//   //     {
//   //       title: 'Add',
//   //       route: { name: 'apps-invoice-add' },
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'eCommerce',
//   //   icon: 'ShoppingCartIcon',
//   //   children: [
//   //     {
//   //       title: 'Shop',
//   //       route: 'apps-e-commerce-shop',
//   //     },
//   //     {
//   //       title: 'Details',
//   //       route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
//   //     },
//   //     {
//   //       title: 'Wishlist',
//   //       route: 'apps-e-commerce-wishlist',
//   //     },
//   //     {
//   //       title: 'Checkout',
//   //       route: 'apps-e-commerce-checkout',
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'User',
//   //   icon: 'UserIcon',
//   //   children: [
//   //     {
//   //       title: 'List',
//   //       route: 'apps-users-list',
//   //     },
//   //     {
//   //       title: 'View',
//   //       route: { name: 'apps-users-view', params: { id: 21 } },
//   //     },
//   //     {
//   //       title: 'Edit',
//   //       route: { name: 'apps-users-edit', params: { id: 21 } },
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'Pages',
//   //   icon: 'FileIcon',
//   //   children: [
//   //     {
//   //       title: 'Authentication',
//   //       icon: 'CircleIcon',
//   //       children: [
//   //         {
//   //           title: 'Login v1',
//   //           route: 'auth-login-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Login v2',
//   //           route: 'auth-login-v2',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Register v1',
//   //           route: 'auth-register-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Register v2',
//   //           route: 'auth-register-v2',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Forgot Password v1',
//   //           route: 'auth-forgot-password-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Forgot Password v2',
//   //           route: 'auth-forgot-password-v2',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Reset Password v1',
//   //           route: 'auth-reset-password-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Reset Password v2',
//   //           route: 'auth-reset-password-v2',
//   //           target: '_blank',
//   //         },
//   //       ],
//   //     },
//   //     {
//   //       title: 'Account Settings',
//   //       route: 'pages-account-setting',
//   //     },
//   //     {
//   //       title: 'Profile',
//   //       route: 'pages-profile',
//   //     },
//   //     {
//   //       title: 'Faq',
//   //       route: 'pages-faq',
//   //     },
//   //     {
//   //       title: 'Knowledge Base',
//   //       route: 'pages-knowledge-base',
//   //     },
//   //     {
//   //       title: 'Pricing',
//   //       route: 'pages-pricing',
//   //     },
//   //     {
//   //       title: 'Blog',
//   //       children: [
//   //         {
//   //           title: 'List',
//   //           route: 'pages-blog-list',
//   //         },
//   //         {
//   //           title: 'Detail',
//   //           route: { name: 'pages-blog-detail', params: { id: 1 } },
//   //         },
//   //         {
//   //           title: 'Edit',
//   //           route: { name: 'pages-blog-edit', params: { id: 1 } },
//   //         },
//   //       ],
//   //     },
//   //     {
//   //       title: 'Mail Templates',
//   //       children: [
//   //         {
//   //           title: 'Welcome',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
//   //         },
//   //         {
//   //           title: 'Reset Password',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
//   //         },
//   //         {
//   //           title: 'Verify Email',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
//   //         },
//   //         {
//   //           title: 'Deactivate Account',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
//   //         },
//   //         {
//   //           title: 'Invoice',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
//   //         },
//   //         {
//   //           title: 'Promotional',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
//   //         },
//   //       ],
//   //     },
//   //     {
//   //       title: 'Miscellaneous',
//   //       icon: 'CircleIcon',
//   //       children: [
//   //         {
//   //           title: 'Coming Soon',
//   //           route: 'misc-coming-soon',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Not Authorized',
//   //           route: 'misc-not-authorized',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Under Maintenance',
//   //           route: 'misc-under-maintenance',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Error',
//   //           route: 'misc-error',
//   //           target: '_blank',
//   //         },
//   //       ],
//   //     },
//   //   ],
//   // },
// ];
